<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">
              {{ `${isEdit ? "Edit" : "New"} Product` }}
            </h4>
            <v-form ref="productForm" @submit.prevent="save()">
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="productModel.name"
                    label="Name"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Product Name is required']"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="productModel.description"
                    label="Description"
                    required
                    counter="50"
                    outlined
                    :rules="[(v) => !!v || 'Product Description is required']"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <base-insurer-picker
                    :chosen-insurer.sync="productModel.insurer"
                    :initial="productModel.insurer"
                    label="Insurer"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Insurer is required']"
                  />
                </v-col>
                <v-col md="6">
                  <base-product-category-picker
                    :chosen-product-category.sync="productModel.category"
                    :initial="productModel.category"
                    label="Product Category"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Product Category is required']"
                  />
                </v-col>
              </v-row>
              <v-btn type="submit" color="success">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      isEdit: false,
      isLoading: false,

      installationImageAsDataURL: null,
      me: "",
      search: null,
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  computed: {
    crumbs() {
      return [
        {
          text: "Products",
          disabled: false,
          href: "/admin/products",
        },
        {
          text: "Product Detail",
          disabled: false,
          href: `/admin/product/${this.$route.params.id}`,
        },
        {
          text: `${this.isEdit ? "Edit" : "New"} Product`,
          disabled: true,
        },
      ];
    },
  },
  watch: {},
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    product: {
      query: gql`
        query Product($id: ID!) {
          product(id: $id) {
            id
            name
            description
            category
            insurer
            # featurePicture
            # price
            documents {
              id
            }
            createdBy
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.productModel.id = this.product.id;
        this.productModel.name = this.product.name;
        this.productModel.description = this.product.description;
        this.productModel.category = this.product.category;
        this.productModel.insurer = this.product.insurer;
        this.productModel.documents = this.product.documents;
      },
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.productForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        self.isLoading = false;
        return;
      }
      if (self.isEdit) {
        await this.updateProduct()
          .then(() => {
            this.$swal("Success!", "Product was updated", "success");
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the product, check data and try again later!",
              "error"
            );
          });
      } else {
        await this.createProduct()
          .then(() => {
            this.$swal("Success!", "Product was created", "success");
            window.location.href = `/admin/product/${self.productModel.id}/edit`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              `Something went wrong when creating the product, check data and try again later!`,
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateProduct() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($product: ProductUpdateInput!) {
              updateProduct(product: $product) {
                id
                updated
              }
            }
          `,
          variables: {
            product: {
              id: self.productModel.id,
              name: self.productModel.name,
              description: self.productModel.description,
              category: self.productModel.category,
              insurer: self.productModel.insurer,
              documents: self.productModel.documents,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the product!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createProduct() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createProduct($product: ProductCreateInput!) {
              createProduct(product: $product) {
                id
              }
            }
          `,
          variables: {
            product: {
              name: self.productModel.name,
              description: self.productModel.description,
              category: self.productModel.category,
              insurer: self.productModel.insurer,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the Product!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createProduct) {
          self.productModel.id = response?.data?.createProduct?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
